<template>
  <div>
    <header class="main-header">
      <!-- Logo -->
      <a href="#" class="logo">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini"><b>INV</b></span>
        <!-- logo for regular state and mobile devices -->
        <!--<span class="logo-lg"><b>SIM INVENTORY</b></span>-->
      </a>
      <!-- Header Navbar: style can be found in header.less -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <a
          href="#"
          class="sidebar-toggle"
          data-toggle="push-menu"
          role="button"
        >
          <span class="sr-only">Toggle navigation</span>
        </a>
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <!-- User Account: style can be found in dropdown.less -->
            <li class="dropdown user user-menu">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <img
                  src="../assets/dist/img/avatar5.png"
                  class="user-image"
                  alt="User Image"
                />
                <span class="hidden-xs">{{nmkar}}</span>
              </a>
              <ul class="dropdown-menu">
                <!-- User image -->
                <li class="user-header">
                  <img
                    src="../assets/dist/img/avatar5.png"
                    class="img-circle"
                    alt="User Image"
                  />

                  <p>
                    {{nmkar}}
                  </p>
                  <small>{{dptkar}}</small>
                </li>
                <!-- Menu Footer-->
                <li class="user-footer">
                  <div class="pull-right">
                    <a
                      href="#"
                      @click="logout()"
                      class="btn btn-default btn-flat"
                      >Sign out</a
                    >
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <aside class="main-sidebar">
      <!-- sidebar: style can be found in sidebar.less -->
      <section class="sidebar">
        <!-- Sidebar user panel -->
        <div class="user-panel">
          <div class="pull-left image">
            <img
              src="../assets/dist/img/avatar5.png"
              class="img-circle"
              alt="User Image"
            />
          </div>
          <div class="pull-left info">
            <p>{{nmkar}}</p>
            <i class="fa fa-circle text-success"></i> Online ( {{dptkar}} )
          </div>
        </div>
        <ul class="sidebar-menu" data-widget="tree">
          <template v-for="link in menu" :key="link.id_tbl_menu">
            <li class="header" v-if="link.link == ''">{{link.nama}}</li>
            <template v-for="parent in link.children" :key="parent.id_tbl_menu">
              <li v-if="parent.link=='/home'">
                <router-link :to="parent.link">
                  <i :class="parent.icon"></i> <span>{{parent.nama}}</span>
                </router-link>
              </li>
              <li v-if="parent.link=='#'" class="treeview">
                <a :href="parent.link">
                  <i :class="parent.icon"></i>
                  <span>{{parent.nama}}</span>
                  <span class="pull-right-container">
                    <i class="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul class="treeview-menu">
                  <template v-for="child in parent.children" :key="child.id_tbl_menu">
                    <li v-if="child.children.length==0" @click="activate(child.id_tbl_menu)" :class="{ active : active_el == child.id_tbl_menu }">
                      <router-link :to="child.link">
                        <i :class="child.icon"></i> {{child.nama}}
                      </router-link>
                    </li>
                    <li v-else class="treeview">
                      <a :href="child.link">
                        <i :class="child.icon"></i>
                        <span>{{child.nama}}</span>
                        <span class="pull-right-container">
                          <i class="fa fa-angle-left pull-right"></i>
                        </span>
                      </a>
                      <ul class="treeview-menu">
                        <li v-for="subchild in child.children" :key="subchild.id_tbl_menu" @click="activate(subchild.id_tbl_menu)" :class="{ active : active_el == subchild.id_tbl_menu }">
                          <router-link :to="subchild.link">
                            <i :class="subchild.icon"></i> {{subchild.nama}}
                          </router-link>
                        </li>
                      </ul>
                    </li>

                  </template>
                </ul>
              </li>

            </template>
          </template>
        </ul>
        
      </section>
    </aside>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
    <footer class="main-footer">
      <div class="pull-right hidden-xs"><b>Version</b> 1.0</div>
      <strong
        >Copyright &copy; 2021
        <a href="javascript:void(0)">Anis Fajar Prakoso</a>.</strong
      >
      All rights reserved.
    </footer>
  </div>
</template>

<script>
var axios = require("axios");
export default {
  name: "adminlayout",
  data(){
    return {
      active_el:0,
      nmkar:"",
      dptkar:"",
      menu:[]
    }
  },
  created(){
    this.nmkar = localStorage.getItem('namakar')
    this.dptkar = localStorage.getItem('deptkar')
    this.getMenu();
  },
  methods: {
    activate:function(el){
        this.active_el = el;
    },
    async getMenu() {
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "user/getlist_v_user_akses_menu_by_nik?nik=" +
        sessionStorage.getItem("kodeuser");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          let data = resp.data.data;
          data.sort(function(a,b){
            return a.id_tbl_menu - b.id_tbl_menu 
          })
          const listToTree = (arr = []) => {
            let map = {}, node, res = [], i;
            for (i = 0; i < arr.length; i += 1) {
                map[arr[i].id_tbl_menu] = i;
                arr[i].children = [];
            }
            for (i = 0; i < arr.length; i += 1) {
                node = arr[i];
                if (node.is_parent !== "0") {
                  arr[map[node.is_parent]].children.push(node);
                }
                else {
                  res.push(node);
                }
            }
            return res;
          };
          this.menu=listToTree(data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    logout: function() {
      sessionStorage.removeItem("token");
      delete axios.defaults.headers.common["key"];
      window.location.href = "/";
    }
  }
};
</script>
